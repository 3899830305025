var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", "offset-y": "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              !_vm.mobileMode
                ? _c("v-text-field", {
                    style: {
                      maxWidth: "calc(100% + " + _vm.searchWidthGrow + "px)",
                      minWidth: "calc(100% + " + _vm.searchWidthGrow + "px)",
                    },
                    attrs: {
                      outlined: "",
                      dense: "",
                      "single-line": "",
                      "hide-details": "",
                      "prepend-inner-icon": "fal fa-search",
                      label: _vm.$t("searchLabel"),
                      disabled: _vm.disabled,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("typing")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "v-badge",
                                {
                                  staticClass: "v-badge-small",
                                  attrs: {
                                    "offset-x": "6",
                                    "offset-y": "10",
                                    color: "primary",
                                    content: _vm.filterCount,
                                    value: _vm.isFiltered,
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          ref: "menuButton",
                                          staticClass: "focus-icon-border",
                                          attrs: {
                                            disabled: _vm.disabled,
                                            color: _vm.isFiltered
                                              ? "primary"
                                              : "default",
                                            "aria-label": _vm.$t("filterLabel"),
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.focusMenu.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("fal fa-sliders")]
                                  ),
                                ],
                                1
                              ),
                              _vm.isFiltered
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-4 focus-icon-border",
                                      attrs: {
                                        color: "error",
                                        "aria-label": _vm.$t("clearLabel"),
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [_vm._v(" fas fa-times-circle ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.localSearchText,
                      callback: function ($$v) {
                        _vm.localSearchText = $$v
                      },
                      expression: "localSearchText",
                    },
                  })
                : _vm._e(),
              _vm.mobileMode
                ? _c(
                    "v-badge",
                    {
                      staticClass: "v-badge-small",
                      attrs: {
                        "offset-x": "6",
                        "offset-y": "10",
                        color: "primary",
                        content: _vm.filterCount,
                        value: _vm.isFiltered,
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            {
                              ref: "menuButton",
                              staticClass: "focus-border",
                              attrs: {
                                disabled: _vm.disabled,
                                color: _vm.isFiltered ? "primary" : "default",
                                "aria-label": _vm.$t("filterLabel"),
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  )
                                    return null
                                  return _vm.focusMenu.apply(null, arguments)
                                },
                              },
                            },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("fal fa-sliders")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-card",
                { attrs: { outlined: "", width: "100%" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "mt-2" },
                    [
                      _c("span", { staticClass: "title-on-border" }, [
                        _vm._v(_vm._s(_vm.$t("showingLabel"))),
                      ]),
                      _vm._t("filters-menu"),
                      _vm.mobileMode
                        ? _c(
                            "v-row",
                            { staticClass: "mt-2", attrs: { justify: "end" } },
                            [
                              _vm.isFiltered
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "", color: "error" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.clearFilter.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("clearLabel")) + " ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
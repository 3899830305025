







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SearchFilter extends Vue {
    @Prop({ required: true, type: Boolean })
    isFiltered!: boolean;

    @Prop({ required: true, type: Number })
    filterCount!: number;

    @Prop({ required: true, type: Function })
    clearFilter!: () => Promise<any>;

    @Prop({ required: false, type: String, default: '' })
    searchText!: string;

    @Prop({ required: false, type: String, default: '0' })
    searchWidthGrow!: string;

    @Prop({ required: true, type: Boolean })
    mobileMode!: boolean;

    @Prop({ required: false, type: Boolean, default: false })
    disabled!: boolean;

    get localSearchText() {
      return this.searchText;
    }

    set localSearchText(val) {
      this.$emit('update:searchText', val);
    }

    focusMenu() {
      this.$emit('focusMenu');
    }

    focusButton() {
      (this.$refs as any).menuButton.$el.focus();
    }
}

